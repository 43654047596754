<template>
  <b-row class="profile-settings h-100">
    <b-col class="profile-list-settings" lg="5">
      <div>
        <router-link
          :class="{ selected: $route.name === 'profile' }"
          :to="{ name: 'profile' }"
          class="list-item"
        >
          <div class="icon-container">
            <i
              :class="[
                $route.name === 'profile' ? 'icon-user-fill' : 'icon-user',
              ]"
            ></i>
          </div>
          <span class="list-item-text">
            {{ $t("profile.personal_information") }}
          </span>
        </router-link>
      </div>
      <div class="list-item">
        <div class="profile-vertical-divider"></div>
      </div>
      <div>
        <router-link
          :class="{ selected: $route.name === 'formPassword' }"
          :to="{ name: 'formPassword' }"
          class="list-item"
        >
          <div class="icon-container">
            <i
              :class="[
                $route.name === 'formPassword' ? 'icon-lock-fill' : 'icon-lock',
              ]"
            ></i>
          </div>
          <span class="list-item-text">
            {{ $t("profile.account_security") }}
          </span>
        </router-link>
      </div>
      <div class="list-item">
        <div class="profile-vertical-divider"></div>
      </div>
      <div>
        <router-link
          :class="{ selected: $route.name === 'formSetting' }"
          :to="{ name: 'formSetting' }"
          class="list-item"
        >
          <div class="icon-container">
            <i
              :class="[
                $route.name === 'formSetting'
                  ? 'icon-notifications-fill'
                  : 'icon-notifications',
              ]"
            ></i>
          </div>
          <span class="list-item-text">
            {{ $t("profile.account_preferences") }}
          </span>
        </router-link>
      </div>
    </b-col>
    <b-col class="profile-content h-100" lg="7">
      <router-view />
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Profile",
  computed: mapState("auth", ["user"]),
};
</script>

<style scoped></style>
